<template>
  <div class="library">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item class="search-form-gutter" :label="$t('gpt.desc')">
          <el-input v-model="desc" />
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('gpt.when')">
          <el-select style="width: 128px" v-model="currentDate" :placeholder="$t('common.all')" clearable filterable>
            <el-option v-for="item in dateList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button type="primary" icon="el-icon-search" @click="clickSearch">{{ $t("common.search") }}</el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter"><el-button class="excel-dl-btn" icon="el-icon-download"
            :disabled="true">{{ $t("analysis.downloadAll") }}</el-button></el-form-item>
      </el-form>
    </div>
    <div class="data-content">
      <p v-if="loading" class="data-loading">
        <i class="el-icon-loading" />&nbsp;{{ $t("common.loading") }}...
      </p>
      <p v-else-if="!tableData || tableData.length === 0" class="data-loading">
        {{ $t("common.noMoreData") }}
      </p>
      <el-row v-else :gutter="10" style="margin-bottom: -10px">
        <el-col class="data-block" :lg="{ span: '5-24' }" v-for="item in tableData" :key="item.url">
          <div class="data-block-content">
            <el-image class="content-image" :src="item.url | imageUrl(false)"
              :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
            <div class="data-block-text">
              <el-row class="content-line">
                # {{ item.desc }}
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
        layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    </div>
  </div>
</template>

<script>
import { fetchTaskDates, fetchImages } from "@/api/gpt";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";
import Guide from "@/components/Guide";
import { imageUrl } from "@/filters";

export default {
  components: {
    AiBreadcrumb,
    Pagination,
    Guide,
  },
  data() {
    return {
      desc: "",
      currentDate: "",
      dateList: [],
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      loading: false,
    };
  },
  methods: {
    initListQuery() {
      this.listQuery = {
        offset: 0,
        limit: 10,
      };
    },
    fetchDateList() {
      fetchTaskDates({ category: "morph" }).then((response) => {
        if (response.success) {
          this.dateList = response.result || [];
        }
      });
    },
    clickSearch() {
      this.initListQuery();
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      fetchImages({
        category: "morph",
        desc: this.desc,
        date: this.currentDate,
        ...this.listQuery,
      }).then((response) => {
        this.loading = false;
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
  },
  mounted() {
    this.initListQuery();
    this.fetchDateList();
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.library {
  .el-col-lg-5-24 {
    width: 1/5 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px 10px 0 10px;
    width: 100%;
    margin-bottom: 9.5px;

    .search-form {
      min-height: 32px;
      display: inline-block;

      .search-form-gutter {
        margin-bottom: 10px;
      }

      /deep/ .el-cascader .el-input__inner {
        height: 32px !important;
      }

      /deep/ .el-cascader__tags {
        padding-top: 2px;
      }
    }

    .download-btn {
      display: inline-block;
    }
  }

  .data-content {
    overflow: hidden;
    font-size: small;
    margin-bottom: -10px;

    .data-loading {
      text-align: center;
      font-size: 14px;
      color: #606266;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .data-block {
      margin-bottom: 10px;

      .data-block-content {
        background-color: #fff;
        padding-bottom: 5px;

        .content-image {
          width: 100%;
          height: 0;
          padding-bottom: 100%;

          /deep/ img {
            position: absolute;
            margin-bottom: 5px;
          }
        }

        .data-block-text {
          margin-top: 5px;
          text-align: center;
        }

        .content-line {
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.65);

          .content-label {
            color: #a9a9a9;
            margin-right: 5px;
            line-height: 27px;
          }

          .content-link {
            margin-top: 5px;
            margin-right: 5px;
            line-height: 27px;
          }
        }
      }
    }
  }
}
</style>